$animation-speed: 10s;
$sliding-speed: .6s;

#k-clippath-svg {
    position: absolute;
    width: 0;
    height: 0;
    z-index: -99;
}

.klausen-slider {
    width: 100%;
    padding-bottom: 42.86%;
    position: relative;
    z-index: 1;
    overflow: hidden;

    @media (max-width: 500px) {
        padding-bottom: 60%;
    }

    &.is-webkit {
        .klausen-slider-slides {
            &.cloned-slides {
                z-index: -1;
            }
            
            &:not(.cloned-slides) {
                clip-path: url(#k-clippath);
                filter: blur(2px);

                .klausen-slider-slide {
                    &::after {
                        content: "";
                        position: absolute;
                        z-index: 10;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background-color: #000;
                        opacity: .5;
                    }
                }
            }
        }
    }

    &:not(.is-webkit) {
        &::after {
            content: "";
            position: absolute;
            z-index: 10;
            width: 102%;
            height: 100%;
            top: 0;
            left: -1%;
            background: url(../img/k-mask.svg) no-repeat center left;
            background-size: cover;
            opacity: .5;
        }
    }
    

    &.no-transition {
        .klausen-slider-slide,
        .klausen-slider-text {
            transition: none;
        }
    }

    .klausen-slider-slides {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .klausen-slider-slide {
        position: absolute;
        width: 110%;
        height: 100%;
        top: 0;
        left: 0;
        background-repeat: no-repeat;
        background-position: center;        
        background-size: cover;
        opacity: 0;
        visibility: hidden;
        transform: translateX(150px) translateZ(0);
        transition: all $sliding-speed;

        &.prepare-to-prev {
            transform: translateX(-150px) translateZ(0);
        }

        &.current {
           z-index: 9; 
        }

        &.fade-in {
            opacity: 1;
            visibility: visible;
            transform: none;
        }

        &.background-animation {
            animation: background-animation $animation-speed linear forwards;
        }

        @keyframes background-animation {
            to { transform: translateX(-8%) rotate(0.01deg) translateZ(0) }
        }
    }

    .klausen-slider-text {
        position: absolute;
        width: 100%;
        padding-bottom: 30px;
        z-index: 11;
        top: 50%;
        left: 50%;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        transform: translate(-30%, -50%);
        transition: all $sliding-speed;

        &.fade-in {
            opacity: 1;
            visibility: visible;
            transform: translate(-50%, -50%);

            & > * {
                &:first-child {
                    animation: text-to-left $animation-speed forwards linear;
                }
    
                &:last-child {
                    animation: text-to-right $animation-speed forwards linear;
                }
            }
        }

        &.fade-out {
            opacity: 0;
            visibility: hidden;
            transform: translate(-70%, -50%);
        }

        & > * {
            width: 100%;
            position: relative;

            &:first-child {
                font-family: 'Didot';
                font-weight: 400;
                font-size: 70px;
                color: #FFF;
                margin: 0;
                padding: 0;
                line-height: initial;
                font-style: italic;

                &::after {
                    display: none;
                }
            }

            &:last-child {
                font-family: 'Muli';
                font-weight: 400;
                font-size: 20px;
                color: #b4a06e;
                max-width: 600px;
                margin: auto;
                line-height: 28px;
            }

            @media (max-width: 768px) {
                &:first-child {
                    font-size: 46px;
                }
    
                &:last-child {
                    font-size: 17px;
                }  
            }

            @media (max-width: 600px) {
                padding: 0 16%;
                box-sizing: border-box; 
            }

            @media (max-width: 500px) {
                &:first-child {
                    font-size: 30px;
                }

                &:last-child {
                    font-size: 15px;
                }  
            }
        }

        @keyframes text-to-left {
            to { transform: translateX(-60px) rotate(0.01deg) translateZ(0) }
        }

        @keyframes text-to-right {
            to { transform: translateX(60px) rotate(0.01deg) translateZ(0) }
        }
    }

    .klausen-slider-arrows {
        display: table;
        font-size: 0;
        position: absolute;
        z-index: 11;
        left: 50%;
        transform: translateX(-50%);

        @media (max-width: 500px) {
            top: auto !important;
            bottom: 20px;
        }

        .klausen-slider-arrow {
            width: 30px;
            height: 30px;
            display: inline-block;
            background: no-repeat center;
            background-size: 12px 16px;
            margin: 0 4px;
            cursor: pointer;

            &.left {
                background-image: url(../img/arrow-prev.svg);
            }

            &.right {
                background-image: url(../img/arrow-next.svg);
            }
        }
    }
}